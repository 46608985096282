import React from 'react';
import styles from './style.module.scss';
import { Breadcrumb, Button, Segmented, Tooltip } from 'antd';
import { NavLink } from 'react-router-dom';
import { InputField } from '@shared/ui/form-items';
import { useFormContext } from 'react-hook-form';
import { DownloadSvg, InfoOutlineSvg, SaveSvg } from '@app/assets/svg';
import { ModalOpenEvent } from '@processes/modal';
import NdaModal from '@shared/ui/nda-modal';
import { EditCvModel } from '@features/edit-cv';
import { AppModel } from '@processes/app';
import { useMutation, useQueryClient } from 'react-query';
import { CvApi, CvModel } from '@entities/cv';
import { NotificationEvent } from '@processes/notification';
import { useEditCv } from '@features/edit-cv/lib';
import { SegmentedValue } from 'antd/lib/segmented';
import eng from '@app/assets/images/eng-flag.svg';
import ru from '@app/assets/images/ru-flag.svg';
import { getDate } from '@shared/lib/getDateString';
import { useAppSelector } from '@shared/model';

const text = {
	en: {
		messageSuccess: 'Profile updated successfully',
		messageError: 'Profile updated is not successful',
		save: 'Save',
		save_download: 'Save & Download',
		download: 'Download',
		modal: 'Download CV with project names?',
		formError: 'Form is not valid',
	},
	ru: {
		messageSuccess: 'Profile обновлен успешно',
		messageError: 'Не удалось обновить Profile',
		save: 'Сохранить',
		save_download: 'Сохранить и скачать',
		download: 'Скачать',
		modal: 'Скачать резюме с названиями проектов?',
		formError: 'Форма заполнена не валидно',
	},
	tooltip: {
		en: {
			ru: 'Format ex.: QA T2 Maksim H. 223, Java D3 Ivan K. 1051',
			en: 'Format ex.: QA T2 Maksim H. 223, Java D3 Ivan K. 1051',
		},
		ru: {
			ru: 'Format ex.: QA T2 Maksim H. 223, Java D3 Ivan K. 1051',
			en: 'Format ex.: QA T2 Maksim H. 223, Java D3 Ivan K. 1051',
		},
	},
	profile: {
		en: {
			en: 'English profile',
			ru: 'Английский профайл',
		},
		ru: {
			en: 'Russian profile',
			ru: 'Русский профайл',
		},
	},
};
const showError = (error: string, locale: 'ru' | 'en') => {
	NotificationEvent.open({
		type: 'warning',
		info: {
			description: error ? `Fields: ${error}` : '',
			message: text[locale].formError,
		},
	});
};
const FormHeader = () => {
	const queryClient = useQueryClient();
	const { guest, locale, language } = AppModel.useAppStore((state) => ({
		guest: state.guest,
		locale: state.locale,
		language: state.language,
	}));

	const isLead = useAppSelector(CvModel.selectors.isLead);
	const SetCvLanguage = AppModel.useAppStore((state) => state.SetCvLanguage);
	const ChangeLocal = AppModel.useAppStore((state) => state.ChangeLocal);
	const { mutate: download, isLoading: isLoadingDownload } = useMutation({
		mutationFn: CvApi.generateFromForm,
	});
	const { mutate: update, isLoading: isLoadingUpdate } = useMutation({
		mutationFn: CvApi.updateCv,
		onSuccess: async () => {
			await queryClient.invalidateQueries([CvApi.getCv.key]);
			NotificationEvent.open({
				type: 'success',
				info: {
					message: text[locale].messageSuccess,
				},
			});
		},
		onError: () => {
			NotificationEvent.open({
				type: 'error',
				info: {
					message: text[locale].messageError,
				},
			});
		},
	});

	const { data, cvId } = useEditCv();
	const {
		control,
		getValues,
		formState: { isValid, errors },
		trigger,
	} = useFormContext<EditCvModel.CvFormDto>();

	const onLanguageChange = (value: SegmentedValue) => {
		SetCvLanguage(value as 'ru' | 'en');
		ChangeLocal(value as 'ru' | 'en');
	};

	const onSave = () => {
		const cvData = getValues();
		if (isValid && language) {
			const cv = EditCvModel.CvFormDto.sendCv(cvData, language);
			const { ruCv, ...rest } = cv;
			if (cvId && data) {
				update({
					language,
					cvId,
					cv: language === 'ru' ? { ruCv: cv, ...rest } : cv,
				});
			}
		} else {
			const errorsString = Object.keys(errors).join(', ');
			showError(errorsString, locale);
			(async () => {
				await trigger();
			})();
		}
	};
	const onSaveAndDownload = () => {
		const nda = getValues().nda;
		if (nda) {
			saveAndDownloadHandler();
		} else {
			ModalOpenEvent.Open({
				title: text[locale].modal,
				component: <NdaModal download={() => saveAndDownloadHandler()} />,
			});
		}
	};
	const onDownload = () => {
		if (!isValid) {
			const errorsString = Object.keys(errors).join(', ');
			showError(errorsString, locale);
			(async () => {
				await trigger();
			})();
		} else {
			const nda = getValues().nda;
			if (nda) {
				downloadHandler();
			} else {
				ModalOpenEvent.Open({
					title: text[locale].modal,
					component: <NdaModal download={() => downloadHandler()} />,
				});
			}
		}
	};

	function saveAndDownloadHandler() {
		const cvData = getValues();
		if (isValid && language) {
			const cv = EditCvModel.CvFormDto.sendCv(cvData, language);
			const { ruCv, ...rest } = cv;
			if (cvId && data) {
				update({
					language,
					cvId,
					cv: language === 'ru' ? { ruCv: cv, ...rest } : cv,
				});
				downloadHandler(true);
			}
		} else {
			const errorsString = Object.keys(errors).join(', ');
			showError(errorsString, locale);
			trigger();
		}
	}

	function downloadHandler(saveAndDownload?: boolean) {
		const cvData = getValues();
		const cv = EditCvModel.CvFormDto.sendCv(cvData, language);
		const { ruCv, ...rest } = cv;
		const cvToSend = language === 'ru' ? { ruCv: cv, ...rest } : cv;
		const currentDate = new Date().toUTCString();

		const fileName = saveAndDownload
			? cv.filename + ' [' + getDate(currentDate) + ']' + (language !== 'ru' ? '_en' : '_ru')
			: cv.filename + (language !== 'ru' ? '_en' : '_ru');
		if (guest) {
			localStorage.setItem(language, JSON.stringify(cvToSend));
		}
		download({
			language,
			nda: cvData.nda,
			cv: cvToSend,
			fileName: fileName,
		});
	}

	const breadcrumbsItems = [
		{
			title: locale === 'en' ? 'Profile' : 'Профиль',
			href: '/',
		},
		{
			title: locale === 'en' ? 'Edit a profile' : 'Редактировать профиль',
		},
	];

	return (
		<div className={styles.header}>
			{!guest && (
				<Breadcrumb className={styles.breadcrumbs} separator=">" items={breadcrumbsItems} />
			)}
			<div className={styles.header__content}>
				{isLead && (
					<InputField
						main={{
							className: styles.filename,
							placeholder: 'Enter name of the file',
							// disabled: language !== 'en',
							suffix: (
								<Tooltip title={text.tooltip[language || 'en'][locale]}>
									<InfoOutlineSvg style={{ color: 'rgba(0,0,0,.45)' }} />
								</Tooltip>
							),
						}}
						name={'filename'}
						control={control}
						rules={{
							required: true,
							validate: (value: string) => {
								if (/[а-яА-ЯЁё]/.test(value)) {
									return language === 'ru'
										? 'Используйте только латинские буквы'
										: 'Use only Latin letters';
								} else {
									return true;
								}
							},
						}}
					/>
				)}

				<div className={styles.control}>
					{!guest && (
						<>
							<Button loading={isLoadingUpdate} type={'primary'} onClick={onSave}>
								<SaveSvg />
								<span>{text[locale].save}</span>
							</Button>

							<Button loading={isLoadingUpdate} type={'primary'} onClick={onSaveAndDownload}>
								<DownloadSvg />
								<span>{text[locale].save_download}</span>
							</Button>
						</>
					)}
					<Button type={'primary'} loading={isLoadingDownload} onClick={onDownload}>
						<DownloadSvg />
						<span>{text[locale].download}</span>
					</Button>
					{guest && (
						<Segmented
							className={styles.segment}
							onChange={onLanguageChange}
							value={language}
							options={[
								{
									label: (
										<div className={styles.segment__item}>
											<img alt={'flag'} className={styles.icon} src={eng} />
											{text.profile.en[locale]}
										</div>
									),
									value: 'en',
								},
								{
									label: (
										<div className={styles.segment__item}>
											<img alt={'flag'} className={styles.icon} src={ru} />
											{text.profile.ru[locale]}
										</div>
									),
									value: 'ru',
								},
							]}
						/>
					)}
				</div>
			</div>
		</div>
	);
};
export default FormHeader;
