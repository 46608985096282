export const fillingInstructionsEn = [
	{
		name: 'Certification',
		text: 'For the SalesForce Department',
	},
];
export const fillingInstructionsRu = [
	{
		name: 'Сертификаты',
		text: 'Для SalesForce департамента',
	},
];
