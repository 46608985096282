import { FC } from 'react';
import styles from './style.module.scss';
import { Button } from 'antd';
import { ModalCloseEvent } from '@processes/modal';
import { useLocale } from '@processes/app/lib';

interface INdaModal {
	download?: () => void;
}

const translation = {
	info: {
		ru: 'Флажок "NDA" не установлен. Проекты будут загружены с реальными именами проектов / клиентов',
		en: 'The "NDA" checkbox is not selected. Projects will be uploaded with real project / customer names',
	},
	download: {
		ru: 'Скачать',
		en: 'Download',
	},
	cancel: {
		ru: 'Закрыть',
		en: 'Cancel',
	},
};

const NdaModal: FC<INdaModal> = ({ download }) => {
	const { locale } = useLocale();
	function onDownload() {
		if (download) {
			download();
		}
		ModalCloseEvent.Close();
	}
	function onClose() {
		ModalCloseEvent.Close();
	}
	return (
		<div className={styles.modal}>
			<div className={styles.info}>
				<span>{translation.info[locale]}</span>
			</div>
			<div className={styles.buttons}>
				<Button type="primary" onClick={onDownload}>
					{translation.download[locale]}
				</Button>
				<Button onClick={onClose}>{translation.cancel[locale]}</Button>
			</div>
		</div>
	);
};

export default NdaModal;
